$(document).ready(function() {
    const otherTsPrefix = $('#organization_other_ts_org_prefix');
    const otherTsPrefixInput = $('#organization_other_ts_org_prefix_input');

    if (otherTsPrefix.val() == "") {
        otherTsPrefix.val("");
        otherTsPrefixInput.hide();
    }

    $("#organization_ts_org_prefix").on("change", function() {
        let selectedValues = $(this).val();

        if (selectedValues && selectedValues.includes("Other")) {
            otherTsPrefixInput.show();
            otherTsPrefix.focus();
        } else {
            otherTsPrefixInput.hide();
            otherTsPrefix.val("");
        }
    });
});